<template>
  <div class="d-flex justify-content-center align-items-center">
    <div class="bg-white w-50 border border-4 border-dark borderRadius">
      <div class="d-flex justify-content-end">
        <div class="fs-5 me-2" @click="$emit('modalChange', num)">x</div>
      </div>
      <div class="mb-4 text-center">
        <slot name="rockMo"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RockModal",
  emits: ["modalChange"],
  props: {
    num: Number,
  },
};
</script>
<style scoped>
@media screen and (max-width: 576px) {
  .w-50 {
    width: 70% !important;
  }
}
</style>
