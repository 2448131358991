import { defineStore } from "pinia";

export const useRockStore = defineStore("rockS", {
  state: () => ({
    onStart: false,
    rocking: "스타트 버튼 눌러주세요",
    mine: 10,
    total: 1000,
    myBat: 1,
    dailyCap: 1000,
    userCap: 10,
    token: 0,
    loop: null,
    timeAttack: 0,
    rockImg: "neon0",
  }),
  //   getters: {
  //     userAddress: (state) => UserAddress(state.account),
  //   },
  actions: {
    setOnStart(msg) {
      this.onStart = msg;
    },
    setRocking(msg) {
      this.rocking = msg.win;
      this.rockImg = msg.img;
    },
    setMine(msg) {
      this.mine = msg;
    },
    setTotal(msg) {
      this.total = msg;
    },
    setMyBat(msg) {
      this.myBat = msg;
    },
    setDailyCap(msg) {
      this.dailyCap = msg;
    },
    setUserCap(msg) {
      this.userCap = msg;
    },
    setToken(msg) {
      this.token = msg;
    },
    setLoop() {
      this.timeAttack = 95; // 트잭보내고 하는 시간 넉넉히 - 5초
      this.loop = setInterval(() => {
        this.timeAttack -= 1;
        if (this.timeAttack <= 0) {
          this.timeAttack = 0;
          this.setMyBat(1);
          clearInterval(this.loop);
        }
      }, 1000);
    },
    clearLoop() {
      clearInterval(this.loop);
    },
    timeAttack0() {
      this.timeAttack = 0;
    },
  },
});
