<template>
  <div>
    <div class="height600 homeBack BMJUA gameBox-pc-shadow">
      <section>
        <div class="ms-2 topText-box text-center">
          <div class="my-4 topText">
            <div class="topTextIn">
              {{ $t("message.rock2") }} : {{ rockStore.mine
              }}<span class="iconColor3">/10</span>
              <QuestionMark
                @click.native="showMsgBoxTwo($t('message.rock19'))"
              />
            </div>
          </div>
          <div class="ms-3 my-4 topText1">
            <div class="topTextIn">
              {{ $t("message.lottery9") }} : {{ rockStore.token }} DLP
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="main d-md-flex justify-content-center">
          <div class="game">
            <div
              class="gameTop d-flex align-items-center justify-content-center"
            >
              <b-img
                class="rock"
                :src="require(`../assets/img/rock/${rockStore.rockImg}.png`)"
                alt="rock paper scissors"
              />
            </div>
            <div class="gameBottom d-flex justify-content-center">
              <div class="d-flex justify-content-around">
                <RockImg img="botton0" @click.native="sendTx(1)" />
                <RockImg img="botton1" class="mx-2" @click.native="sendTx(0)" />
                <RockImg img="botton2" @click.native="sendTx(2)" />
              </div>
              <b-img
                class="imgCoin"
                src="../assets/img/rock/bottonSide.png"
                alt="game machine parts"
              />
            </div>
          </div>
          <div class="d-flex align-items-end ms-2">
            <div class="deroleeContainer">
              <div class="talk py-3 text-center" v-if="rockStore.onStart">
                <div class="talkUp mtC">
                  {{ $t("message.rock0")
                  }}<b-img
                    class="mb-1"
                    src="../assets/img/rock/star.png"
                    alt="star"
                  />
                </div>
                <div class="talkDown">
                  {{ rockStore.myBat }} DLP{{ $t("message.rock1") }}
                </div>
              </div>
              <div
                v-else
                @click="getWallet()"
                class="talk py-3 pointer text-center"
              >
                <div class="talkUp">
                  Today : {{ rockStore.total
                  }}<span class="iconColor3">/1000</span>
                </div>
                <div class="talkDown mt-1">
                  Start!
                  <b-img
                    class="mb-1"
                    src="../assets/img/rock/star.png"
                    alt="star"
                  />
                </div>
              </div>
              <b-img
                class="derolee"
                src="../assets/img/rock/derolee.png"
                alt="mascot"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- 가위바위보 효과 -->
      <div class="effectB WHTL position-absolute" v-if="game">
        <!-- 잭팟  -->
        <div class="position-absolute acction" v-if="jackpot">
          <img
            class="mix125x180 w1050h800 z1 mixBlendS position-absolute"
            src="../assets/img/rock/jackpotR22.png"
            alt="effect rainbow2"
          />
          <img
            class="mix125x180 w1050h800 z1 mixBlendS position-absolute"
            src="../assets/img/rock/light.png"
            alt="effect light"
          />
          <img
            class="mix1 z1 mixBlendS position-absolute"
            src="../assets/img/rock/jackpotR1.png"
            alt="effect rainbow"
          />
          <img
            class="mix2 z1 position-absolute"
            src="../assets/img/rock/jacpot.png"
            alt="jackpot"
          />
          <img
            class="mix3 position-absolute"
            src="../assets/img/rock/money.png"
            alt="effect money"
          />
        </div>
        <!-- 승리 -->
        <div class="position-absolute acction" v-if="win">
          <img
            class="mix125x180 w1050h800 z1 mixBlendS position-absolute"
            src="../assets/img/rock/light.png"
            alt="effect light"
          />
          <img
            class="mix00 w1050h800 z1 mixBlendS position-absolute"
            src="../assets/img/rock/lightW.png"
            alt="effect light2"
          />
          <img
            class="mix21 z1 position-absolute"
            src="../assets/img/rock/win.png"
            alt="win"
          />
          <img
            class="mix3 position-absolute"
            src="../assets/img/rock/money.png"
            alt="effect money"
          />
        </div>
        <!-- 패배 -->
        <div v-if="lose">
          <div class="mix40 WHTL mixBlendS position-absolute"></div>
          <img
            class="mix21 z1 position-absolute loseT"
            src="../assets/img/rock/lose.png"
            alt="lose"
          />
        </div>
      </div>
      <!-- 무승부 -->
      <RockModal
        v-if="drawSelect"
        class="pointer effectB WHTL position-absolute"
        :num="1"
        @modalChange="modalChange"
        @click.native="(drawSelect = false), (game = false)"
      >
        <div slot="rockMo">
          <div class="fs-5">
            {{ $t("message.rock3")
            }}<b-img
              class="mb-1"
              src="../assets/img/rock/star.png"
              alt="star"
            />
            {{ $t("message.rock4") }}
          </div>
          <div class="fs-3">
            <strong class="iconColor">{{ $t("message.rock5") }}</strong>
            {{ $t("message.rock6") }} {{ rockStore.timeAttack
            }}{{ $t("message.rock7") }}
          </div>
          <div>total count</div>
          <div>win : {{ winRate }}</div>
          <div>draw : {{ drawRate }}</div>
          <div>lose : {{ loseRate }}</div>
          <div>jackpot : {{ jackpotRate }}</div>
        </div>
      </RockModal>
      <!-- 모달 기본  -->
      <RockModal
        v-if="modalOpen"
        class="pointer effectB WHTL position-absolute"
        :num="0"
        @click.native="(modalOpen = false), (game = false)"
        @modalChange="modalChange"
      >
        <div slot="rockMo">
          <div class="fs-5 mx-3 mt-2">
            {{ modalText }}
            <b-img class="mb-1" src="../assets/img/rock/star.png" alt="star" />
          </div>
          <div v-if="game">
            <div>total count</div>
            <div>win : {{ winRate }}</div>
            <div>draw : {{ drawRate }}</div>
            <div>lose : {{ loseRate }}</div>
            <div>jackpot : {{ jackpotRate }}</div>
          </div>
        </div>
      </RockModal>
      <KlipModal :klipC="klipCR" :canvas="canvasR" @klipChange="klipChangeR" />
    </div>
    <Footer />
  </div>
</template>
<script>
import { request } from "klip-sdk";
import {
  getBlock,
  getSingedTx,
  getRock,
  getSingedAppove,
} from "../control/caverG";
import { api } from "../control/axios";
import { apiKlip } from "../control/axiosKlip";
import { fromWei, BN } from "../control/fromWei";
import { rock } from "../control/contractData";
import { allowance, myBalance } from "../control/contractToken";
import { floor10 } from "../control/floor";
import { approveTx, sendRockTx } from "../control/sendTx";
import { mobile, klipApprove, klipRock } from "../control/klip";
import { getReceipt } from "../control/txToReceipt";
import { useContractStore } from "../stores/contractView";
import { useAccountStore } from "../stores/account";
import { useRockStore } from "../stores/rock";
import { usePageStore } from "../stores/nextPage";
import modalData from "../assets/data/modal.json";
import RockModal from "../components/rock/RockModal.vue";
import KlipModal from "../components/klip/KlipModal.vue";
import RockImg from "../components/rock/RockImg.vue";
import Footer from "../components/basicUse/footer.vue";
import QuestionMark from "../components/Question.vue";
export default {
  name: "Rock",
  components: { RockModal, RockImg, KlipModal, Footer, QuestionMark },
  setup() {
    const contractStore = useContractStore();
    const accountStore = useAccountStore();
    const rockStore = useRockStore();
    const pageStore = usePageStore();
    return { contractStore, accountStore, rockStore, pageStore };
  },
  watch: {
    // 내 멤버쉽 등급에 따라, 원하는 등급이 되고 싶을 때, 스테이킹 해야 할 가격 계산
    async grade(val) {
      if (!val) return;
      this.switchFee(this.grade);
      //지수표현식으로 나와서 빅넘버 사용
      this.calculateJoin();
    },
  },
  async mounted() {
    this.interb = setInterval(() => {
      const result = this.numForI % 3;
      this.rockStore.setRocking(this.list[result]);
      this.numForI++;
    }, 500);
    // 지금 연결 완료
    if (this.accountStore.account !== "") {
      // 방문한적 없음
      if (!this.pageStore.rockFV) {
        this.pageStore.setRockVisit();
        await this.startR();
      }
    }
    this.accountStore.$subscribe(async () => {
      if (this.$route.path === "/rock") {
        if (!this.pageStore.rockFV) {
          if (this.accountStore.account !== "") {
            this.pageStore.setRockVisit();
            await this.startR();
          }
        }
      }
    });
  },
  destroyed() {
    clearInterval(this.interb);
    clearInterval(this.timerR);
  },
  data() {
    return {
      getSingedTx,
      getBlock,
      getRock,
      fromWei,
      allowance,
      api,
      apiKlip,
      getSingedAppove,
      myBalance,
      modalData,
      rock,
      floor10,
      BN,
      approveTx,
      mobile,
      klipApprove,
      getReceipt,
      sendRockTx,
      klipRock,
      request,
      list: [
        {
          win: "가위",
          img: "neon2",
        },
        {
          win: "보",
          img: "neon1",
        },
        {
          win: "주먹",
          img: "neon0",
        },
      ],
      interb: null,
      amount: null,
      loop: null,
      block: null,
      win: false,
      winM: false,
      lose: false,
      loseM: false,
      jackpot: false,
      jackpotM: false,
      drawSelect: false,
      numForI: 0,
      modalOpen: false,
      modalText: null,
      winRate: 0,
      loseRate: 0,
      drawRate: 0,
      jackpotRate: 0,
      game: false,
      klipCR: false,
      canvasR: null,
      timerR: null,
    };
  },
  methods: {
    async startR() {
      this.contractStore.overRay(true, false);

      const wallet = sessionStorage.getItem("wallet");

      const myAllowance = await this.allowance(
        this.rock,
        this.accountStore.account,
        wallet
      );

      if (Number(myAllowance) < 5000000000000000000000) {
        await this.showMsgBoxOne(wallet);
      } else {
        await this.startBasic(wallet);
      }
      this.contractStore.overRay(false, false);
    },

    getWallet() {
      this.pageStore.setShowSelect(true);
    },

    async basicSetRock() {
      // [회차, 전체 캡, 이번회차 전체 게임수, 유저 캡, 이번회차 유저 게임수, 유저 배팅 배율, 하루 기준시간, 86400, 비긴시간]
      const result = await this.getRock(this.accountStore.account);
      let block = await this.getBlock();
      const today = Number(result[5]) + Number(result[6]);
      // block이 더 크면 초기화되기 때문에
      if (today > block) {
        // 하루 지났을때 상태값 초기화
        this.rockStore.setDailyCap(result[0]);
        this.rockStore.setTotal(result[0] - result[1]);
        this.rockStore.setUserCap(result[2]);
        this.rockStore.setMine(result[2] - result[3]);
      }

      // 배팅금액 설정
      this.rockStore.setMyBat(Number(result[4]) + 1);
      // 배팅금액이 1DLP보다 클때
      if (Number(result[4]) > 0) {
        // 비기고 100초 이후
        if (Number(block) >= Number(result[7]) + 100) {
          this.rockStore.setMyBat(1);
        } else {
          // 비기고 100초 이내
          let time = Number(block) - Number(result[7]);
          const timeLoop = setInterval(() => {
            time -= 1;
            if (time <= 0) {
              // 비기고 100초 지났을 때
              this.rockStore.setMyBat(1);
              clearInterval(timeLoop);
            }
          }, 1000);
        }
      }
    },

    async sendTx(num) {
      // 시작버튼 눌렀는지 확인
      if (this.rockStore.onStart) {
        // 오늘의 사용제한 확인
        if (this.rockStore.mine > 0 && this.rockStore.total > 0) {
          this.contractStore.overRay(true, true);
          await this.sendTXDLP(num);
        } else {
          // 오늘의 사용제한 초과
          this.modalText = this.$t("message.rock8");
          this.modalOpen = true;
        }
      } else {
        // 시작버튼 안눌렀을 때
        this.modalText = this.$t("message.rock9");
        this.modalOpen = true;
      }
    },
    // 가위바위보 0
    async sendTXDLP(num) {
      const wallet = sessionStorage.getItem("wallet");
      const balance = await this.myBalance(this.accountStore.account, wallet);
      this.contractStore.setMyTokenBalance(balance);
      this.rockStore.setToken(this.floor10(balance, -2));

      // DLP 충분한지 확인
      if (Number(this.rockStore.token) >= this.rockStore.myBat) {
        await this.sendTxMain(num, balance, wallet);
      } else {
        // DLP 불충분
        this.contractStore.overRay(false, true);
        this.modalText = this.$t("message.rock10");
        this.modalOpen = true;
      }
    },
    // 가위바위보 1
    async sendTxMain(num, balance, wallet) {
      if (this.contractStore.isMetamask) {
        if (wallet == "true") {
          //메타마스크
          await this.rockMetamask(num, balance);
        } else if (wallet == "false") {
          // 카이카스
          await this.rockKaikas(num, balance);
        }
      } else {
        // 클립지갑일 때
        await this.sendKlip(1, this.rock, num, this.rockStore.myBat, balance);
      }
    },

    async rockMetamask(num, balance) {
      // 트잭전송
      const result = await this.sendRockTx(
        this.accountStore.account,
        num,
        this.rockStore.myBat
      );
      if (result.state) {
        // 이벤트 디코드만
        const data = await this.getReceipt(null, true, result.data);

        if (data.state) {
          // 디코드된 정보로 화면출력
          this.afterTx(data.data, balance);
        } else {
          this.showMsgBoxTwo(data.msg);
        }
      } else {
        this.showMsgBoxTwo("fail");
      }
    },

    async rockKaikas(num, balance) {
      // 트잭 사인
      const tx = await this.getSingedTx(
        num,
        this.rockStore.myBat,
        this.accountStore.account
      );
      let result;
      try {
        // 가스비 위임 및 이벤트 디코드된 정보
        result = await this.api("/api/delegationFee", "post", {
          param: [tx],
        });
        this.afterTx(result, balance);
      } catch (error) {
        console.log(error);
        this.contractStore.overRay(false, true);
        this.showMsgBoxTwo(this.$t("message.rock18"));
      }
    },

    afterTx(resultTx, balance) {
      this.amount = fromWei(resultTx[1]);
      // 게임 후(트잭실패 경후의 수가 너무 많아서 지금 함) 배팅금액 만큼 빼고 수익만큼 플러스
      const setDLP =
        Number(balance) - Number(this.rockStore.myBat) + Number(this.amount);
      this.contractStore.setMyTokenBalance(setDLP.toString());

      const setTT =
        Number(this.rockStore.token) -
        Number(this.rockStore.myBat) +
        Number(this.amount);
      this.rockStore.setToken(this.floor10(setTT, -2));

      // 하루 총 1000번 (미완료)
      // 하루 인당 10번
      this.rockStore.setMine(this.rockStore.userCap - resultTx[2]);
      this.rockStore.setTotal(this.rockStore.dailyCap - resultTx[3]);
      this.rockStore.setMyBat(Number(resultTx[4]) + 1);

      this.rockStore.timeAttack0();
      this.contractStore.overRay(false, true);

      this.loseRate = resultTx[5][0].data;
      this.drawRate = resultTx[5][1].data;
      this.winRate = resultTx[5][2].data;
      this.jackpotRate = resultTx[5][3].data;

      if (resultTx[0] == 0) {
        this.phaseLose();
      } else if (resultTx[0] == 1) {
        this.phaseDrwa();
      } else if (resultTx[0] == 2) {
        this.phaseWin();
      } else if (resultTx[0] == 777) {
        this.phaseJackpot();
      }
    },

    phaseWin() {
      this.win = true;
      this.game = true;
      this.rockStore.clearLoop();
      setTimeout(() => {
        this.win = false;
        this.modalText = `${this.$t("message.rock11a")}${this.amount} ${this.$t(
          "message.rock11"
        )}`;
        this.modalOpen = true;
      }, 2000);
    },
    phaseLose() {
      this.lose = true;
      this.game = true;
      this.rockStore.clearLoop();
      setTimeout(() => {
        this.lose = false;
        this.modalText = `${this.$t("message.rock12")}${this.amount}${this.$t(
          "message.rock13"
        )}`;
        this.modalOpen = true;
      }, 2000);
    },
    phaseDrwa() {
      this.drawSelect = true;
      this.game = true;
      // 100초 타임어택
      this.rockStore.setLoop();
    },
    phaseJackpot() {
      this.jackpot = true;
      this.game = true;
      this.rockStore.clearLoop();
      setTimeout(() => {
        this.jackpot = false;
        this.modalText = `${this.$t("message.rock14")}${this.amount}${this.$t(
          "message.rock15"
        )}`;
        this.modalOpen = true;
      }, 2000);
    },

    modalChange(msg) {
      switch (msg) {
        case 0:
          this.modalOpen = false;
          break;
        case 1:
          this.drawSelect = false;
          break;
      }
    },

    // 모달창 ------------------------------------------------
    // this$bvModal을 못찾아서 여기둠
    showMsgBoxTwo(msg) {
      let modal = this.modalData;
      modal.okTitle = this.$t("message.mef7");

      this.$bvModal.msgBoxOk(msg, modal);
    },

    async showMsgBoxOne(wallet) {
      let modal = this.modalData;
      const msg = this.$t("message.rock16");
      modal.okTitle = this.$t("message.mef7");
      modal.cancelTitle = this.$t("message.mef8");
      modal.cancelVariant = "danger";

      this.$bvModal.msgBoxConfirm(msg, modal).then(async (value) => {
        // ok이 일때만
        if (value) {
          if (this.contractStore.isMetamask) {
            if (wallet == "true") {
              // 메타마스크
              await this.approveTx(this.rock);
              // 위에서 한번 꺼서
              this.contractStore.overRay(true, false);
              await this.startBasic(wallet);
            } else if (wallet == "false") {
              // 카이카스
              this.delegateTx();
            }
          } else {
            // 클립지갑일 때
            await this.sendKlip(0, this.rock, null, null, null);
            await this.startBasic(wallet);
          }
        }
      });
    },

    // 카이카스 approve
    async delegateTx() {
      // approve 함수 사인 트잭 만들기
      const tx = await this.getSingedAppove(
        this.accountStore.account,
        this.rock
      );
      // 대납
      try {
        await this.api("/api/delegateApprove", "post", {
          param: [tx],
        });
        await this.startBasic("false");
      } catch (error) {
        this.contractStore.overRay(false, true);
        this.showMsgBoxTwo(this.$t("message.rock18"));
      }
    },

    // klip approve 및 가위바위보
    async sendKlip(num, spender, myRock, myBet, balance) {
      let res;
      if (num === 0) {
        res = await this.klipApprove("DLP ROCK", spender);
      } else {
        // 가위바위보 트잭
        res = await this.klipRock(myRock, myBet);
      }

      if (!res.state) {
        this.showMsgBoxTwo(this.$t("message. rock17"));
      } else {
        // 모바일 아니면 캔버스 스캔할 수 있도록 뛰어줘야함
        if (!this.mobile()) {
          this.klipCR = true;
          this.canvasR = res.canvas;
        } else {
          this.request(res.key, () => alert("mobile plz"));
        }
        // pc, 모바일 둘 다 응답 기다려야함
        this.klipWait(res.key, num, balance);
      }
    },

    // klip에서 결과 나올때 기다림 공통
    klipWait(key, num, balance) {
      this.timerR = setInterval(async () => {
        const res = await this.apiKlip(
          `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${key}`
        );
        if (res.data.status === "completed") {
          clearInterval(this.timerR);
          this.klipCR = false;
          if (res.data.result.status === "success") {
            // 트잭 전송 성공
            if (num === 1) {
              this.klipGetRecipt(res, balance);
            }
          } else {
            // 트잭 전송 실패
            this.showMsgBoxTwo("fail");
          }
        }
      }, 2100);
    },
    // klip 가위바위보 결과 처리
    async klipGetRecipt(res, balance) {
      // tx으로 receipt 획득
      const result = await this.getReceipt(
        res.data.result.tx_hash,
        false,
        null
      );
      if (result.state) {
        // 결과 화면에 보여주기
        this.afterTx(result.data, balance);
      } else {
        this.showMsgBoxTwo(result.msg);
      }
    },

    async startBasic(wallet) {
      await this.basicSetRock();
      const balance = await this.myBalance(this.accountStore.account, wallet);
      this.contractStore.setMyTokenBalance(balance);
      this.rockStore.setToken(this.floor10(balance, -2));

      this.rockStore.setOnStart(true);
      this.contractStore.overRay(false, true);
    },

    klipChangeR(msg) {
      this.klipCR = msg;
    },
  },
};
</script>
<style scoped>
.position-absolute.acction {
  height: 50%;
  width: 50%;
  top: 12%;
  left: 5%;
}
.height600 {
  margin: 1rem auto 2rem;
  padding: 2rem 3rem 0;
  position: relative;
  height: 743px;
  width: 90%;
}
.mixBlendS {
  mix-blend-mode: screen;
}
.z1 {
  z-index: 1;
}
.mix125x180 {
  top: -125px;
  left: -180px;
}
.mix00 {
  top: -75px;
  left: -185px;
}
.w1050h800 {
  width: 1050px;
  height: 800px;
}
.mix1 {
  width: 1000px;
  height: 700px;
  top: -50px;
  left: -100px;
}
.mix2 {
  top: 250px;
  left: 130px;
}
.mix21.loseT {
  top: 40%;
  left: 35%;
}
.mix21 {
  top: 250px;
  left: 250px;
}
.mix21.loseT {
  top: 40%;
  left: 35%;
}
.mix3 {
  width: 1000px;
  top: -100px;
  left: -130px;
}
.WHTL {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.effectB {
  background-color: rgba(0, 0, 0, 0.35);
}
.mix40 {
  background-image: url("../assets/img/rock/loseI.png");
  background-size: 130%;
  background-position: -90px -30px;
}
.homeBack {
  background-image: url("../assets/img/rock/homeB.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}
.rock {
  width: 166px;
  height: 146px;
  margin-top: 20px;
  margin-right: 8px;
}
.gameTop {
  width: 355px;
  height: 321px;
  background-image: url("../assets/img/rock/gameTop.png");
  background-size: cover;
}
.gameBottom {
  margin-top: -1px;
  width: 355px;
  height: 122px;
  background-image: url("../assets/img/rock/gameBottom.png");
  background-size: cover;
}

.imgCoin {
  margin-left: 5px;
  widows: 50px;
  height: 109px;
}
.BMJUA {
  font-family: "BMJUA";
}
.derolee {
  width: 156px;
  height: 164px;
  margin-top: 10px;
  animation: deroleeMove 0.7s linear 0s infinite alternate;
  /* animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2s; */
}
@keyframes deroleeMove {
  0% {
    height: 164px;
    margin-top: 10px;
  }
  20% {
    height: 168px;
    margin-top: 6px;
  }
  100% {
    height: 174px;
    margin-top: 0px;
  }
}

.talk {
  width: 174px;
  height: 113px;
  background-size: cover;
  background-image: url("../assets/img/rock/talk.png");
}
.talkUp {
  font-size: 13pt;
}

.talkDown {
  font-size: 15pt;
}
.topText-box {
  display: flex;
}
.topText {
  background-image: url("../assets/img/rock/text.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 43%;
  height: 43px;
}
.topText1 {
  background-image: url("../assets/img/rock/text1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 43%;
  height: 43px;

  /* width: 182px;
  height: 43px; */
}
.topTextIn {
  font-size: 13pt;
  padding-top: 10px;
}
.main {
  padding-top: 4rem;
  padding-left: 3rem;
  margin-left: 3rem;
}
.mtC {
  margin-top: 0.25rem;
}
@media screen and (max-width: 1023px) {
  .talk {
    width: 144px;
    height: 94px;
  }
  .position-absolute.acction {
    left: 10%;
  }
  .height600 {
    background-size: cover;
    height: 80vh;
    margin: 0;
    padding: 2vh 0 10vh;
    width: 100%;
  }
  .w1050h800 {
    width: 80vw;
    height: 80vh;
  }
  .mix125x180 {
    left: -5vw;
  }
  .mix1 {
    width: 90vw;
    top: -10vw;
  }
  .mix2 {
    top: 30vh;
    left: 10vw;
    width: 50vw;
  }
  .mix3 {
    width: 90vw;
    /* top: -5vh;
    left: -5vw; */
  }
  .mix21 {
    top: 35vh;
    left: 25vw;
    width: 42vw;
  }
  .mix00 {
    top: -5vh;
    left: -2vh;
  }
  .mix40 {
    background-position: -90px -30px;
    background-repeat: no-repeat;
  }
  .talkUp {
    font-size: 11pt;
  }
}

@media screen and (max-width: 768px) {
  .position-absolute.acction {
    left: 13%;
    top: 17%;
  }
  .mix1 {
    top: -30vw;
  }

  .mix2 {
    top: 25vh;
    left: 10vw;
    width: 50vw;
  }

  .topText {
    width: 48%;
  }
  .topText1 {
    width: 48%;
  }
  .main {
    padding: 3rem 0 0;
  }
  .d-md-flex {
    display: flex;
  }
}

@media screen and (max-width: 576px) {
  .position-absolute.acction {
    left: 10%;
  }
  @keyframes deroleeMove {
    0% {
      height: 100px;
      margin-top: 10px;
    }
    20% {
      height: 104px;
      margin-top: 6px;
    }
    100% {
      height: 110px;
      margin-top: 0px;
    }
  }
  .height600 {
    height: 80vh;
    padding: 0;
  }
  .topText-box {
    flex-direction: column;
    align-items: center;
  }
  .topTextIn {
    font-size: 11pt;
    padding-top: 13px;
  }
  .topText {
    width: 75%;
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
  .topText1 {
    background-image: url("../assets/img/rock/text1-mobi.png");
    width: 75%;
    margin-top: -10px !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  .talk {
    width: clamp(120px, 45%, 144px);
    background-size: contain;
    background-image: url("../assets/img/rock/talk-b.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  .homeBack {
    background-image: url("../assets/img/rock/homeS.png");
  }
  .game {
    margin: 0;
  }
  .gameTop {
    height: 248px;
    width: 274px;
  }
  .gameBottom {
    height: 91px;
    width: 274px;
  }
  .derolee {
    width: 95px;
    height: 100px;
    margin-top: 10px;
  }
  .main {
    margin: auto;
    padding: 0;
    display: grid;
    place-items: center;
  }
  .deroleeContainer {
    height: 10vh;
    width: 72vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .talkUp {
    font-size: 10pt;
    margin-top: 3%;
  }

  .talkDown {
    margin-top: 0 !important;
    font-size: 14pt;
  }
  .bottonSize {
    height: 80px;
  }
  .imgCoin {
    height: 80px;
  }
  .ms-4 {
    margin-left: 0 !important;
  }
  .mix1 {
    height: 70vh;
    left: -18%;
    width: 85vw;
  }
  .mix3 {
    width: 80vw;
    left: 0;
  }
  .mix21 {
    top: 20vh;
    left: 12vw;
  }
  .mix21.loseT {
    left: 28%;
  }
  .mix00 {
    top: -15vh;
  }
}

@media screen and (max-width: 374px) {
  .talkUp {
    font-size: 9pt;
    margin-top: 10%;
  }
  .talkDown {
    font-size: 12pt;
    line-height: 0.5;
  }
  .height600 {
    height: 100vh;
  }
  .main {
    display: flex;
    flex-direction: column-reverse;
  }
  .mtC {
    margin-top: 0.75rem;
  }
}
@media screen and (max-width: 362px) {
  .topTextIn {
    font-size: 8pt;
    padding-top: 15px;
  }
}
</style>
