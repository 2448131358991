<template>
  <div
    class="height100 align-items-center back d-flex justify-content-center flex-column"
  >
    <a
      href="https://klayswap.com/ko/swap?inputCurrency=0x0000000000000000000000000000000000000000&outputCurrency=0x4d87baa66061a3bb391456576cc49b77540b9aa9"
      target="_blank"
      >스왑</a
    >
  </div>
</template>
<script>
import { usePageStore } from "../stores/nextPage";
import { useAccountStore } from "../stores/account";
export default {
  name: "Swap",
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  async mounted() {
    // 지금 연결 완료
    if (this.accountStore.account !== "") {
      // 방문한적 없음
      if (!this.pageStore.swapFV) {
        this.pageStore.setSwapVisit();
        // 시작 함수
      }
    }
    this.accountStore.$subscribe(async () => {
      if (this.$route.path === "/swap") {
        if (!this.pageStore.swapFV) {
          if (this.accountStore.account !== "") {
            this.pageStore.setSwapVisit();
            // 시작 함수
          }
        }
      }
    });
  },
};
</script>
