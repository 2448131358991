import { callWeb3 } from "./getWeb3";
import { api } from "./axios";
import { myAsyncFunction } from "./sleep";
import eventABI from "../assets/data/event.json";

export const getReceipt = async (tx, isMetaMask, receipt) => {
  let decodedLogs;
  let result;
  const web3 = await callWeb3("true");

  let a;
  let b;

  if (!isMetaMask) {
    // 클립 트랜잭션 해쉬로 Receipt 가져와서 이벤트 로그 분석 준비
    try {
      result = await web3.eth.getTransactionReceipt(tx);
    } catch (error) {
      console.log(error);
      await myAsyncFunction();
      alert("wait plz");
      result = await web3.eth.getTransactionReceipt(tx);
    }
    a = result.logs[2].data;
    b = result.logs[2].topics.slice(1);
  } else {
    // 메타마스크 이벤트 로그 분석 준비
    // 카이카스는 대납 후 서버에서 함
    result = receipt;
    a = result.events.RockResult.raw.data;
    b = result.events.RockResult.raw.topics.slice(1);
  }

  try {
    // 이벤트 로그 디코드
    decodedLogs = web3.eth.abi.decodeLog(eventABI, a, b);
  } catch (error) {
    console.log(error);
    return { state: false, msg: "decodeLog error", data: null };
  }

  // 서버 다녀와야됨
  let winRate;
  // 잭팟일때는 파람으로 3 넣어줌
  if (decodedLogs.win == 777) {
    try {
      winRate = await api("/api/winrate", "post", {
        param: [3],
      });
    } catch (error) {
      alert(error);
    }
  } else {
    // 잭팟이 아닐때는 win(0,1,2)을 파람으로 넣어줌
    try {
      winRate = await api("/api/winrate", "post", {
        param: decodedLogs.win,
      });
    } catch (error) {
      alert(error);
    }
  }

  const returnValue = [
    decodedLogs.win,
    decodedLogs.bet,
    decodedLogs.times,
    decodedLogs[3],
    decodedLogs[4],
    winRate,
  ];
  return { state: true, msg: null, data: returnValue };
};
