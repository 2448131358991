<template>
  <b-img
    class="bottonSize pointer"
    :src="require(`../../assets/img/rock/${img}.png`)"
    alt="rock paper scissors"
  />
</template>
<script>
export default {
  name: "RockImg",
  props: {
    img: String,
  },
};
</script>
<style scoped>
.bottonSize {
  widows: 78px;
  height: 109px;
}
</style>
