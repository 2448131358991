<template>
  <div>
    <div class="lottery gameBox-pc-shadow">
      <div class="myInfo">
        <div class="enterBalance">
          {{ $t("message.lottery8") }}: {{ lotteryStore.enterBalance }} DLP
        </div>
        <div class="walletBalance">
          {{ $t("message.lottery9") }}: {{ lotteryStore.walletBalance }} DLP
        </div>
      </div>

      <div class="main">
        <div class="enterBox">
          <div>
            <div>
              {{ $t("message.lottery0") }}{{ lotteryStore.lotteryId
              }}{{ $t("message.lottery1") }}
            </div>
            <div class="totalBalance">
              <span class="total">{{ totalBalance }}</span>
              <span class="symbol">DLP</span>
            </div>
          </div>
          <button @click="enter" :disabled="lotteryStore.isDisabled">
            {{ $t("message.lottery2") }}
          </button>
          <p class="note">{{ $t("message.lottery3") }}</p>
        </div>

        <div class="infoBox">
          <div class="start">
            {{ $t("message.lottery5") }}: {{ lotteryStore.start }}
          </div>
          <div class="close">
            {{ $t("message.lottery6") }}: {{ lotteryStore.close }}
          </div>
          <h3>{{ $t("message.lottery4") }}: {{ lotteryStore.blockHeight }}</h3>

          <div class="history">
            <div>{{ $t("message.lottery7") }}</div>
            <ul>
              <li v-for="(winner, index) in lotteryStore.history" :key="index">
                {{ $t("message.lottery12") }}{{ index + 1
                }}{{ $t("message.lottery13") }}: {{ winner.slice(0, 4) }}...{{
                  winner.slice(-4)
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="dirori">
          <div>
            <img src="../assets/img/dirori_box.png" alt="" class="box" />
            <div>
              <p>
                {{ $t("message.lottery10")
                }}<img src="../assets/img/star.png" alt="" class="star" />
              </p>
              <span>{{ $t("message.lottery11") }}</span>
            </div>
          </div>
          <img src="../assets/img/dirori.png" alt="dirori" />
        </div>
      </div>

      <KlipModal :klipC="klipCR" :canvas="canvasR" @klipChange="klipChangeR" />
    </div>
    <Footer />
  </div>
</template>

<!-- 기능 추가 필요:승인, 지갑변경 -->

<script>
import { request } from "klip-sdk";
import { usePageStore } from "../stores/nextPage";
import { useAccountStore } from "../stores/account";
import { useLotteryStore } from "../stores/lottery";
import {
  getBlock,
  // getLottery,
  enterLottery,
  getSingedAppove,
} from "../control/caverG";
import { api } from "../control/axios";
import { apiKlip } from "../control/axiosKlip";
import { lottery } from "../control/contractData";
import { approveTx } from "../control/sendTx";
import { mobile, klipApprove, klipLotteryEnter } from "../control/klip";
import { sendLotteryEnterTx } from "../control/sendTx";
import modalData from "../assets/data/modal.json";
import KlipModal from "../components/klip/KlipModal.vue";
import { useContractStore } from "../stores/contractView";
import Footer from "../components/basicUse/footer.vue";
import { myBalance, allowance } from "../control/contractToken";

export default {
  name: "Lottery",
  components: { KlipModal, Footer },
  setup() {
    const lotteryStore = useLotteryStore();
    const contractStore = useContractStore();
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { contractStore, accountStore, pageStore, lotteryStore };
  },
  destroyed() {
    clearInterval(this.timerR);
    clearInterval(this.timerTB);
  },
  data() {
    return {
      totalBalance: 0,
      sendLotteryEnterTx,
      getBlock,
      // getLottery,
      enterLottery,
      getSingedAppove,
      lottery,
      myBalance,
      allowance,
      approveTx,
      mobile,
      klipApprove,
      klipLotteryEnter,
      api,
      apiKlip,
      modalData,
      request,
      klipCR: false,
      canvasR: null,
      timerR: null,
      timerTB: null,
    };
  },
  async mounted() {
    this.timerTB = setInterval(async () => {
      const res = await this.api("/api/lottery/getBalance", "get");
      this.totalBalance = res;
    }, 1000 * 5);

    if (this.lotteryStore.gameStatus === null) {
      this.basicSetLottery();
    }

    // 지금 연결 완료
    if (this.accountStore.account !== "") {
      // 방문한적 없음
      if (!this.pageStore.lotteryFV) {
        this.pageStore.setLotteryVisit();
        // 시작 함수
        this.pageIn();
      }
    }

    this.accountStore.$subscribe(async () => {
      if (this.$route.path === "/lottery") {
        if (!this.pageStore.lotteryFV) {
          if (this.accountStore.account !== "") {
            this.pageStore.setLotteryVisit();
            // 시작 함수
            this.pageIn();
          }
        }
      }
    });
  },
  watch: {
    blockHeight() {
      if (this.blockHeight > 0 && this.blockHeight == this.lotteryStore.close) {
        this.gameStatus = "off";
      }
    },
  },
  methods: {
    async pageIn() {
      this.contractStore.overRay(true, false);
      //토큰 승인
      const wallet = sessionStorage.getItem("wallet");

      const myAllowance = await this.allowance(
        this.lottery,
        this.accountStore.account,
        wallet
      );

      if (Number(myAllowance) < 5000000000000000000000) {
        await this.showMsgBoxOne(wallet);
      } else {
        await this.startBasic(wallet);
      }
      this.contractStore.overRay(false, false);
    },
    async showMsgBoxOne(wallet) {
      let modal = this.modalData;
      const msg = this.$t("message.lottery16");
      // modal.okTitle = this.setLanguage.mef7;
      // modal.cancelTitle = this.setLanguage.mef8;
      modal.okTitle = this.$t("message.newThird3");
      modal.cancelTitle = this.$t("message.mef8");
      modal.cancelVariant = "danger";

      this.$bvModal.msgBoxConfirm(msg, modal).then(async (value) => {
        if (value) {
          if (this.contractStore.isMetamask) {
            if (wallet == "true") {
              // 메타마스크
              await this.approveTx(this.lottery);
              this.contractStore.overRay(true, false);
              await this.startBasic(wallet);
            } else if (wallet == "false") {
              // 카이카스, 가스비 대납
              this.contractStore.overRay(true, true);
              const tx = await this.getSingedAppove(
                this.accountStore.account,
                this.lottery
              );
              try {
                await this.api("/api/delegateApprove", "post", {
                  param: [tx],
                });
                await this.startBasic(wallet);
              } catch (error) {
                console.log(error);
                this.contractStore.overRay(false, true);
                this.showMsgBoxTwo("fail");
              }
            }
          } else {
            // 클립지갑일 때
            await this.sendKlip(0, this.lottery);
            await this.startBasic(wallet);
          }
        }
      });
    },

    async basicSetLottery() {
      //기본정보 업데이트
      const lottery = await this.api("/api/lottery/getLottery", "get");

      this.lotteryStore.setLotteryId(Number(lottery.currentLotteryId) + 1);
      this.lotteryStore.setHistory(lottery.history);

      const currentBlock = await this.getBlock();
      if (
        lottery.startTime <= currentBlock &&
        currentBlock < lottery.closeTime
      ) {
        this.lotteryStore.setGameStatus("on", lottery);
      } else {
        this.lotteryStore.setGameStatus("off", null);
      }
    },

    showMsgBoxTwo(msg) {
      let modal = this.modalData;
      // modal.okTitle = this.setLanguage.mef7;
      modal.okTitle = this.$t("message.mef7");

      this.$bvModal.msgBoxOk(msg, modal);
    },

    async sendKlip(num, spender) {
      let res;
      if (num === 0) {
        res = await this.klipApprove("DLP LOTTERY", spender);
      } else {
        res = await this.klipLotteryEnter();
      }

      if (!res.state) {
        this.showMsgBoxTwo("Approve Failed");
      } else {
        // 모바일 아니면 캔버스 스캔할 수 있도록 뛰어줘야함
        if (!this.mobile()) {
          this.klipCR = true;
          this.canvasR = res.canvas;
        } else {
          this.request(res.key, () => alert("mobile plz"));
        }
        // pc, 모바일 둘 다 응답 기다려야함
        this.klipWait(res.key, num);
      }
    },

    // klip에서 결과 나올때 기다림 공통
    klipWait(key, num) {
      this.timerR = setInterval(async () => {
        const res = await this.apiKlip(
          `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${key}`
        );
        if (res.data.status === "completed") {
          clearInterval(this.timerR);
          this.klipCR = false;
          if (res.data.result.status === "success") {
            // 트잭 전송 성공
            if (num !== 0) {
              await this.enterResult(true, num, true);
              this.lotteryStore.setIsDisabled(false);
            }
          } else {
            // 트잭 전송 실패
            this.showMsgBoxTwo("fail");
          }
        }
      }, 2100);
    },
    async startBasic(wallet) {
      const balance = await this.myBalance(this.accountStore.account, wallet);
      this.lotteryStore.setWalletBalance(Number(balance).toLocaleString());
      this.lotteryStore.setAccount(this.accountStore.account);

      this.contractStore.setMyTokenBalance(balance);

      this.contractStore.overRay(false, true);
    },

    klipChangeR(msg) {
      this.klipCR = msg;
    },

    async enter() {
      this.lotteryStore.setIsDisabled(true);

      const amount = 1;
      const wallet = sessionStorage.getItem("wallet");
      const balance = await this.myBalance(this.accountStore.account, wallet);

      this.contractStore.setMyTokenBalance(balance);
      this.lotteryStore.setWalletBalance(Number(balance).toLocaleString());

      // DLP 충분한지 확인
      if (Number(balance) >= amount) {
        this.contractStore.overRay(true, true);
        await this.sendTxMain(amount, wallet);
      } else {
        // DLP 불충분
        this.contractStore.overRay(false, true);
        let modal = this.modalData;
        const msg = this.$t("message.lottery15");
        modal.okTitle = this.$t("message.mef7");

        this.$bvModal.msgBoxOk(msg, modal);
      }
    },

    async sendTxMain(amount, wallet) {
      if (this.contractStore.isMetamask) {
        if (wallet == "true") {
          // 메타마스크
          await this.enterMetamask(amount);
        } else if (wallet == "false") {
          // 카이카스, 가스비 대납
          await this.enterKaikas(amount);
        }
      } else {
        // 클립지갑일 때
        await this.sendKlip(amount, this.lottery);
      }
    },
    async enterMetamask(amount) {
      // 트잭전송
      const result = await this.sendLotteryEnterTx(this.accountStore.account);
      await this.enterResult(result.state, amount, true);
      this.lotteryStore.setIsDisabled(false);
    },
    async enterKaikas(amount) {
      const tx = await this.enterLottery();

      try {
        const result = await this.api("/api/lottery/delegationFee", "post", {
          param: [tx, this.accountStore.account],
        });

        await this.enterResult(result, amount, false);
        this.lotteryStore.setIsDisabled(false);
      } catch (error) {
        console.log(error);
      }
    },
    async enterResult(result, amount, updateBalance) {
      if (result) {
        const account = this.accountStore.account;
        const wallet = sessionStorage.getItem("wallet");
        let modal = this.modalData;
        const msg = this.$t("message.lottery14");
        modal.okTitle = this.$t("message.mef7");
        this.$bvModal.msgBoxOk(msg, modal);

        this.lotteryStore.setEnterBalance(amount);
        this.lotteryStore.setWalletBalance(
          Number(await this.myBalance(account, wallet)).toLocaleString()
        );

        if (updateBalance) {
          this.api("/api/lottery/updateLotteryPlayers", "post", {
            param: [this.accountStore.account],
          });
        }
      } else {
        alert("Transaction Error");
      }
      this.contractStore.overRay(false, true);
    },
    // async startGame() {
    //   const result = await startLottery(this.duration);
    //   if (result.status) {
    //     this.lottery = await this.getLottery();
    //     this.gameStatus = "on";
    //   } else {
    //     alert("트랜잭션 오류");
    //   }
    // },
  },
};
</script>

<style scoped lang="css">
@font-face {
  font-family: "BMJUA";
  src: url("../assets/font/BMJUA_ttf.ttf");
}
@keyframes jump {
  0% {
    top: -10px;
  }

  to {
    top: 0px;
  }
}

.lottery {
  font-family: "BMJUA";
  background-image: url(../assets/img/lottery_pc_bg.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 743px;
  margin: 1rem auto 2rem;
  padding: 4vh 0 4vh;
  width: 90%;
  font-size: 15pt;
  position: relative;
  /* background: url("../../assets/img/test-bg.png"),
    url("../../assets/img/test-light.png");
  background-blend-mode: screen; */
}

button {
  width: 70%;
  margin: 10px auto;
  background-color: #ff8300;
  color: white;
  border: 1px solid #ff8300;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: white;
  color: #ff8300;
  border: 1px solid white;
}
button:disabled {
  background-color: #572f04;
  color: rgb(170, 163, 163);
  border: 1px solid #572f04;
  cursor: not-allowed;
}

h3 {
  font-size: 17pt;
  background-color: #f7f3ff;
  color: black;
  font-weight: 600;
  line-height: 4;
  margin-top: 5%;
}
.myInfo {
  display: flex;
  justify-content: space-between;
  width: 85%;
}
.myInfo > div {
  padding-top: 1%;
  width: 45%;
  line-height: 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
.enterBalance {
  background-image: url(../assets/img/mybalance.png);
  padding-left: 7%;
}
.walletBalance {
  background-image: url(../assets/img/enter_balance.png);
  padding-left: 10%;
}
.main {
  position: relative;
  display: flex;
  width: 85%;
  height: clamp(200px, 78%, 480px);
  justify-content: space-between;
  margin-top: 3.5%;
}
.main > div {
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  text-align: center;
  /* padding: 5% 5%; */
}
.enterBox {
  background-image: url(../assets/img/lottery_pc_enter.png);
  width: 36%;
  color: white;
}

.enterBox > div {
  text-align: left;
  margin: 50% 15% 0;
}
p {
  font-size: 11pt;
  margin-top: 5%;
}
.note {
  width: 57%;
  margin: 5% auto auto;
}
.total {
  font-size: 50pt;
  font-weight: 400;
  background-image: linear-gradient(to bottom, #ff8300, #fedf2f);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
}
.symbol {
  font-size: 20pt;
  padding-left: 5px;
}
.infoBox {
  background-image: url(../assets/img/lottery_pc_info.png);
  width: 63%;
  color: #a8a8a8;
  padding: 5% 0.5% 5%;
}

.history {
  height: 42%;
  box-sizing: border-box;
  overflow: scroll;
  margin-top: 15%;
}
.history > div {
  color: black;
}
li {
  list-style: none;
}
ul {
  padding: 0;
}
.history::-webkit-scrollbar {
  width: 13px;
}
.history::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-clip: padding-box;
  border: 3px solid transparent;
}
.history::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}
.history::-webkit-scrollbar-track-piece:start {
  background: transparent;
}
.history::-webkit-scrollbar-corner {
  background: transparent;
}
.dirori {
  display: flex;
  position: absolute;
  height: 15vh;
  bottom: -20%;
  right: -5%;
}
.dirori > img {
  position: relative;
  top: -20px;
  animation: jump 0.2s ease-in Infinite Alternate;
}

.dirori > div {
  position: relative;
  display: grid;
  place-items: center;
}

.dirori > div > div {
  position: absolute;
  text-align: center;
}
.dirori > div > div > p {
  margin: 0;
  position: relative;
  top: 4px;
}
.star {
  vertical-align: top;
  margin-left: 2px;
}
@media screen and (max-width: 1023px) {
  .lottery {
    background-size: cover;
    height: 80vh;
    padding: 4vh 0 0;
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  button {
    width: 85%;
    height: clamp(18px, 3.5vh, 29px);
    margin: auto;
    margin-top: 1px;
  }
  h3 {
    font-size: 11pt;
    font-weight: 400;
    line-height: 3;
    width: 60%;
    position: absolute;
    left: 1px;
    text-align: center;
  }
  .lottery {
    background-image: url(../assets/img/lottery_mob_bg.png);
    background-position: top;
    background-size: cover;
    height: 80vh;
    padding: 0;
    width: 100%;
    font-size: 10pt;
    position: relative;
    /* background: url("../../assets/img/test-bg.png"),
    url("../../assets/img/test-light.png");
  background-blend-mode: screen; */
  }

  .myInfo {
    width: 95%;
    margin-top: 5%;
  }
  .myInfo > div {
    padding-top: 2%;
    width: 50%;
    line-height: 3.5;
  }
  .main {
    margin: 0;
    width: 90%;
    height: clamp(235px, 60%, 500px);
    flex-direction: column;
    justify-content: space-evenly;
  }
  .main > div {
    width: 100%;
    background-size: cover;
    text-align: unset;
  }
  .enterBox {
    display: grid;
    place-items: center;
    background-image: url(../assets/img/lottery_mobi_enter.png);
    height: clamp(113px, 42%, 150px);
    padding: 5% 6%;
  }
  .infoBox {
    position: relative;
    background-image: url(../assets/img/lottery_mobi_info.png);
    height: clamp(120px, 52%, 250px);
    padding: 5% 6%;
  }
  .start {
    position: absolute;
    margin-top: 14%;
    width: 60%;
    left: 1px;
    text-align: center;
  }
  .close {
    position: absolute;
    margin-top: 18%;
    width: 60%;
    left: 1px;
    text-align: center;
  }
  .history {
    width: 40%;
    position: absolute;
    right: 0;
    height: 80%;
    overflow: scroll;
    margin-top: 0;
    text-align: center;
    padding-top: 16px;
    padding-left: 13px;
  }

  .enterBox > div {
    text-align: center;
    margin: 0;
  }
  p {
    font-size: 9pt;
    margin: 1% auto auto;
  }
  .totalBalance {
    line-height: 1;
  }
  .total {
    font-size: 32pt;
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }
  .symbol {
    font-size: 22pt;
  }
  .dirori {
    display: flex;
    justify-content: space-evenly;
    bottom: -35%;
    left: 0;
    height: 16vh;
  }
  .start {
    margin-top: 22%;
  }
  .close {
    margin-top: 28%;
  }
  .note {
    width: 100%;
    margin: 2% auto auto;
    text-align: center;
  }
}

@media screen and (max-width: 374px) {
  .lottery {
    font-size: 11px;
  }
  h3 {
    font-size: 10pt;
    width: 63%;
  }
  /* .dirori {
    height: auto;
  } */
  .start {
    margin-top: 30%;
  }
  .close {
    margin-top: 36%;
  }
  .history {
    width: 35%;
  }
}
@media screen and (max-width: 320px) {
  h3 {
    font-size: 11px;
    width: 60%;
  }
  .start {
    margin-top: 25%;
  }
  .close {
    margin-top: 30%;
  }
}
</style>
