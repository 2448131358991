import { defineStore } from "pinia";
import { getBlock } from "../control/caverG";
import { api } from "../control/axios";

export const useLotteryStore = defineStore("lotteryS", {
  state: () => ({
    account: null,
    gameStatus: null,
    lotteryId: " ",
    start: "---",
    close: "---",
    blockHeight: "---",
    enterBalance: 0,
    walletBalance: 0,
    history: [],
    isDisabled: true,
    offTimer: null,
  }),

  actions: {
    async setGameStatus(msg, lottery) {
      this.gameStatus = msg;

      if (msg === "on") {
        this.start = lottery.startTime;
        this.close = lottery.closeTime;
        if (this.account !== null) {
          this.isDisabled = false;
        }

        this.setBlockHeight(true);
      } else if (msg === "off") {
        this.blockHeight = "---";
        this.isDisabled = true;
        const delay = await this.calcStartDelay();

        if (delay > 0) {
          this.offTimer = setTimeout(
            this.requestLotteryStatus,
            delay + 10 * 1000
          );
        } else {
          alert("lottery system error");
        }
      }
    },
    async calcStartDelay() {
      // const startHour = 15; //테스트
      const startHour = 19;

      // 1. 현재 시간(Locale)
      const curr = new Date();

      // 2. UTC 시간 계산
      const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

      // 3. UTC to KST (UTC + 9시간)
      const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
      const kr_curr = new Date(utc + KR_TIME_DIFF);

      //시작 시간의 Date 객체를 생성합니다.
      const startTime = new Date(
        kr_curr.getFullYear(),
        kr_curr.getMonth(),
        kr_curr.getDate(),
        startHour
      );

      //동작시간의 밀리세컨과 현재시간의 밀리세컨의 차이를 계산합니다.
      const timer = startTime.getTime() - kr_curr.getTime();

      return timer;
    },
    async requestLotteryStatus() {
      const lottery = await api("/api/lottery/getLottery", "get");
      const currentBlock = await getBlock();
      if (
        lottery.startTime <= currentBlock &&
        currentBlock < lottery.closeTime
      ) {
        //게임이 시작 되였으면
        this.setGameStatus("on", lottery);
        this.lotteryId = Number(lottery.currentLotteryId) + 1;
        this.history = lottery.history;
        this.totalBalance = 0;
      } else {
        //게임이 시작 안되였으면 10초뒤 다시 상태 조회
        this.offTimer = setTimeout(this.requestLotteryStatus, 10 * 1000);
      }
    },
    async setAccount(msg) {
      this.account = msg;
      if (this.gameStatus === "on") {
        this.isDisabled = false;

        const lottery = await api("/api/lottery/getLottery", "get");
        const getElNum = (arr, el) =>
          arr.filter((v) => v.toLowerCase() === el).length;
        this.enterBalance = getElNum(lottery.playesInfo, this.account);
      }
    },
    setLotteryId(msg) {
      this.lotteryId = msg;
    },
    setStart(msg) {
      this.start = msg;
    },
    setClose(msg) {
      this.close = msg;
    },

    setEnterBalance(msg) {
      this.enterBalance += msg;
    },
    setWalletBalance(msg) {
      this.walletBalance = msg;
    },
    setHistory(msg) {
      this.history = msg;
    },
    setIsDisabled(msg) {
      this.isDisabled = msg;
    },
    async setBlockHeight(msg) {
      if (msg) {
        const block = await getBlock();
        this.blockHeight = block;

        const timer = setInterval(() => {
          if (this.blockHeight == this.close) {
            this.setGameStatus("off");
            clearInterval(timer);
          } else {
            this.blockHeight += 1;
          }
        }, 1000);
      } else {
        this.blockHeight = "---";
      }
    },
  },
});
